.root {
    display: flex;
    margin: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    line-height: 48px;
    font-weight: 500;
}

.text {
    margin-bottom: -2px;
}
