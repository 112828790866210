.link {
    height: 48px;
    padding: 16px;
    margin: 16px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
