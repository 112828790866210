.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    :global(html.fullscreen) .header {
        top: 64px;
    }
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer {
    text-align: center;
}
