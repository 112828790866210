import React, { FC, SVGProps } from 'react';

export const BackspaceIcon: FC<SVGProps<SVGSVGElement>> = ({ width = 36, height = 36 }) => {
    return (
        <svg width={width} height={width} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m6.81504 4.20001c-.31434 0-.61038.1478-.79928.39904l-5.563909 7.40005c-.267629.3559-.267629.8459 0 1.2019l5.563909 7.4c.1889.2512.48494.399.79928.399h16.18496c.5523 0 1-.4477 1-1v-14.79999c0-.55228-.4477-1-1-1zm2.37103 13.60929c-.24809-.2543-.24809-.6666 0-.9209l4.31553-4.4232-4.05238-4.15357c-.2481-.2543-.2481-.66659 0-.92088.2481-.2543.65038-.2543.89848 0l4.0523 4.15355 4.0523-4.15357c.2481-.25429.6504-.25429.8985 0 .2481.2543.2481.66659 0 .92088l-4.0524 4.15359 4.3155 4.4232c.2481.2543.2481.6666 0 .9209s-.6503.2543-.8984 0l-4.3155-4.4233-4.3155 4.4233c-.24808.2543-.65033.2543-.89843 0z"
                fill="currentColor"
            />
        </svg>
    );
};
