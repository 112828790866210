.button {
    width: 48px;
    height: 48px;
    padding: 16px;
    margin: 16px;
    border: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: none;
    cursor: pointer;
}
